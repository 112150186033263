@import './reset.css';
@import './variables.scss';
@import './fonts.scss';
@import './mixins.scss';

:root {
  color-scheme: light;
  --swiper-pagination-bullet-size: 10px;
  --swiper-theme-color: #a9187b;
  --swiper-navigation-color: #a9187b;
}

/* stylelint-disable */
html,
body,
#root,
#__next {
  width: 100%;
  height: 100%;
}

#__next {
  position: relative;
  z-index: 1;
}

/* stylelint-enable */
body {
  @include custom-scrollbar();
  font-family: $ff-primary;
  color: $color-firm9;
  letter-spacing: 0.05em;
  font-size: $font-size9;
}

%headingBase {
  color: $color-firm9;
  font-weight: 700;
  text-transform: uppercase;
}

a {
  color: inherit;
}

h1 {
  @extend %headingBase;
  @include fontSize1;
}

h2 {
  @extend %headingBase;
  @include fontSize2;
}

h3 {
  @extend %headingBase;
  @include fontSize3;
}

h4 {
  @extend %headingBase;
  @include fontSize4;
}

h5 {
  @extend %headingBase;
  @include fontSize7;
}

h6 {
  @extend %headingBase;
  @include fontSize9;
}
