html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
textarea,
input {
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: none;
  outline: none;
}

html:before,
html:after,
body:before,
body:after,
div:before,
div:after,
span:before,
span:after,
applet:before,
applet:after,
object:before,
object:after,
iframe:before,
iframe:after,
h1:before,
h1:after,
h2:before,
h2:after,
h3:before,
h3:after,
h4:before,
h4:after,
h5:before,
h5:after,
h6:before,
h6:after,
p:before,
p:after,
blockquote:before,
blockquote:after,
pre:before,
pre:after,
a:before,
a:after,
abbr:before,
abbr:after,
acronym:before,
acronym:after,
address:before,
address:after,
big:before,
big:after,
cite:before,
cite:after,
code:before,
code:after,
del:before,
del:after,
dfn:before,
dfn:after,
em:before,
em:after,
img:before,
img:after,
ins:before,
ins:after,
kbd:before,
kbd:after,
q:before,
q:after,
s:before,
s:after,
samp:before,
samp:after,
small:before,
small:after,
strike:before,
strike:after,
strong:before,
strong:after,
sub:before,
sub:after,
sup:before,
sup:after,
tt:before,
tt:after,
var:before,
var:after,
b:before,
b:after,
u:before,
u:after,
i:before,
i:after,
center:before,
center:after,
dl:before,
dl:after,
dt:before,
dt:after,
dd:before,
dd:after,
ol:before,
ol:after,
ul:before,
ul:after,
li:before,
li:after,
fieldset:before,
fieldset:after,
form:before,
form:after,
label:before,
label:after,
legend:before,
legend:after,
table:before,
table:after,
caption:before,
caption:after,
tbody:before,
tbody:after,
tfoot:before,
tfoot:after,
thead:before,
thead:after,
tr:before,
tr:after,
th:before,
th:after,
td:before,
td:after,
article:before,
article:after,
aside:before,
aside:after,
canvas:before,
canvas:after,
details:before,
details:after,
embed:before,
embed:after,
figure:before,
figure:after,
figcaption:before,
figcaption:after,
footer:before,
footer:after,
header:before,
header:after,
hgroup:before,
hgroup:after,
menu:before,
menu:after,
nav:before,
nav:after,
output:before,
output:after,
ruby:before,
ruby:after,
section:before,
section:after,
summary:before,
summary:after,
time:before,
time:after,
mark:before,
mark:after,
audio:before,
audio:after,
video:before,
video:after {
  box-sizing: border-box;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

html,
body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
  outline: none;
}

textarea {
  resize: none;
}

input,
textarea {
  appearance: none !important; /* stylelint-disable-line */
}

input:focus,
textarea:focus {
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-weight: 400;
}
